<template>
  <main class="main page">
    <div class="quiz social-work">
      <div class="quiz-content">
        <template v-if="quizData">
          <h1 class="quiz__heading">
            The Basics
          </h1>
          <transition name="fade" mode="out-in">
            <form class="form">
              <div class="grid__inner">
                <fieldset class='form__question'>
                  <div class="form__content">
                    <legend class="quiz__subheading">Let&rsquo;s get some basic info</legend>
                    <div class="form__item form__item--text inputColumns">
                      <input
                        type="text"
                        autocomplete="given-name"
                        placeholder="First name"
                        v-model="inputValue['first_name']"
                        @blur="checkInputValues('first_name')"
                      >
                      {{this.name}}
                    </div>
                    <!-- <div class="form__item form__item--text inputColumns form__item--half">
                      <input
                        type="text"
                        autocomplete="family-name"
                        placeholder="Last name"
                        v-model="inputValue['last_name']"
                        @blur="checkInputValues('last_name')"
                      >
                    </div> -->
                    <div class="form__item form__item--text inputColumns">
                      <input
                        type="email"
                        autocomplete="email"
                        placeholder="Email address"
                        v-model="inputValue['email']"
                        @blur="checkInputValues('email')"
                      >
                      {{this.email}}
                    </div>
                    <div class="form__item form__item--text inputColumns form__item--half show-password__reveal--text" >
                      <input
                        :type="this.showPassword ? 'text' : 'password'"
                        autocomplete="new-password"
                        placeholder="Password"
                        v-model="inputValue['newPassword']"
                      >
                    </div>
                    <div class="form__item form__item--text inputColumns form__item--half show-password__reveal--text">
                      <input
                        :type="this.showPassword ? 'text' : 'password'"
                        autocomplete="new-password"
                        placeholder="Password (repeat)"
                        v-model="inputValue['newPasswordAgain']"
                        @blur="checkInputValues('password')"
                      >
                    </div>
                    <div class="form__item form__item--checkbox inputColumns form__item--half">
                      <input
                        type="checkbox"
                        value="showPassword"
                        name="show-password"
                        id="show-password"
                        v-model="showPassword"
                      > Show password
                    </div>
                    <transition name="warning-slide-down" mode="out-in">
                      <div v-if="userErrorMessage" class="form-validation warning">
                        {{ userErrorMessage }}
                      </div>
                    </transition>
                      <input
                        type="hidden"
                        v-model="inputValue['social_id']"
                        name="social_id" >
                      <input
                        type="hidden"
                        v-model="inputValue['type']"
                        name="type" >
                  </div>
                </fieldset>
                <div class="quiz__container--full">
                  <div class="quiz__container--half">
                    <p>Already have an account?
                      <a class="underline" @click="showModalSignup">Sign In</a>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </transition>
        </template>
      </div>

      <div class="quiz__navigation">
        <button
          @click="prevStep"
          class="quiz__navigation__button quiz__navigation__button--back"
        >
          Back
        </button>
        <button
          @click="nextStep"
          class="quiz__navigation__button quiz__navigation__button--continue primary"
        >
          Continue
        </button>
      </div>
        <div class="social-btn">
          <button class="g-social-btn" @click="login">
            <img src="@/assets/google.svg" alt="Login with google" />
          </button>
          <vue-apple-login
                  color="black"
                  type="sign in"
                  width="45"
                  height="45"
                  :border="true"
                  :radius="100"
                  mode="logo-only"
                  :onSuccess="onSuccess"
                  :onFailure="onFailure"
              ></vue-apple-login>
        </div>
      <Loader :visible="loading" fixed />
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { useRoute } from 'vue-router'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import * as quizData from '@/components/quiz/quizDataNew'
import { validateEmailString } from '@/assets/scripts/helpers'
import Loader from '@/components/Loader'

export default {
  name: 'Quiz Registration',
  components: {
    Loader
  },
  data () {
    const route = useRoute()
    if (route?.query?.email || route?.query?.name) {
      return {
        loading: false,
        quizData,
        inputValue: { first_name: route?.query?.name, email: route?.query?.email, type: (route?.query?.type === 'google') ? 'facebook' : 'totree', social_id: (route?.query?.type === 'facebook') ? route?.query?.id : '' },
        userErrorMessage: '',
        allFieldsValid: false,
        showPassword: false,
        signedIn: false
      }
    } else if (route?.query?.social) {
      var decodedStr = Base64.decode(route?.query?.social)
      const socialData = JSON.parse(decodedStr)
      return {
        loading: false,
        quizData,
        inputValue: { first_name: socialData.name, email: socialData.email, type: socialData.type, social_id: socialData.id, newPassword: socialData.id, newPasswordAgain: socialData.id },
        userErrorMessage: '',
        allFieldsValid: false,
        showPassword: false,
        signedIn: false
      }
    } else {
      return {
        loading: false,
        quizData,
        inputValue: {},
        userErrorMessage: '',
        allFieldsValid: false,
        showPassword: false,
        signedIn: false
      }
    }
  },

  computed: {
    ...mapState(['fbFunctions', 'firebaseAuth', 'firestore']),
    ...mapGetters([
      'currentUser'
    ])
  },

  methods: {
    ...mapMutations([
      'setModalSignup',
      'setGlobalError'
    ]),
    ...mapActions([
      'userAuthCheck',
      'initUserProfileData'
    ]),

    toggleShowPassword (event) {

    },

    checkInputValues (target) {
      const checkEmail = validateEmailString(this.inputValue.email)
      this.userErrorMessage = ''
      this.allFieldsValid = true
      if (target === 'first_name' || target === 'all') {
        if (!this.inputValue.first_name) {
          this.userErrorMessage = 'A First Name is Required.'
          this.allFieldsValid = false
        }
      }
      // if (target === 'last_name' || target === 'all') {
      //   if (!this.inputValue.last_name) {
      //     this.userErrorMessage = 'A Last Name is required.'
      //     this.allFieldsValid = false
      //   }
      // }
      if (target === 'email' || target === 'all') {
        if (!checkEmail.isValid) {
          this.userErrorMessage = checkEmail.message
          this.allFieldsValid = false
        }
      }
      if (target === 'password' || target === 'all') {
        if (!this.inputValue.newPassword || this.inputValue.newPassword.length < 6) {
          this.userErrorMessage = 'Passwords must be 6 characters or more.'
          this.allFieldsValid = false
        } else if (this.inputValue.newPassword !== this.inputValue.newPasswordAgain) {
          this.userErrorMessage = 'Passwords do not match.'
          this.allFieldsValid = false
        }
      }
    },

    handleAccountCreationError (error) {
      this.allFieldsValid = false
      // error.code, error.message are returned from FirebaseAuth
      // see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_3 for error types.

      switch (error.code) {
        case 'auth/email-already-in-use':
          this.userErrorMessage = 'This email is already in use. Please Sign In Below.'
          this.allFieldsValid = false

          break
        case 'auth/invalid-email':
          this.userErrorMessage = 'This email address is invalid.'
          this.allFieldsValid = false
          break
        case 'auth/operation-not-allowed':
          this.userErrorMessage = 'This operation is not allowed. ' + error
          this.allFieldsValid = false
          break
        case 'auth/weak-password':
          this.allFieldsValid = false
          this.userErrorMessage = 'Password should be at least 6 characters.'
          break
        default:
          this.userErrorMessage = 'An unknown sign-up error has occurred. ' + error
          this.allFieldsValid = false
          break
      }

      return false
    },

    /**
     * Create User New User account with email
     */
    async createUserAccount () {
      this.loading = true
      // TODO: Move most of this stuff to the back end so that it can all be handled in "bulk"

      // AUTH STEP 1 - Sign in with email / password
      // First, create the user account.
      let response
      try {
        response = await createUserWithEmailAndPassword(this.firebaseAuth, this.inputValue.email, this.inputValue.newPassword)

        localStorage.setItem('uid', response?.user.uid)
      } catch (error) {
        this.loading = false
        this.handleAccountCreationError(error)
        return false
      }

      await this.userAuthCheck()
      // Then add relevant data from the form

      try {
        await this.initUserProfileData({
          first_name: this.inputValue.first_name,
          // last_name: this.inputValue.last_name,
          email_address: this.inputValue.email,
          displayname: this.inputValue.first_name,
          date_createdAt: response.user.metadata.createdAt,
          date_creationTime: response.user.metadata.creationTime,
          user_type: this.inputValue.type,
          social_id: this.inputValue.social_id
        })
      } catch (err) {
        this.setGlobalError('Sorry, your user profile data couldn\'t be saved.')
      }

      // Finally, add data to auth profile and redirect.

      try {
        await updateProfile(this.firebaseAuth.currentUser, {
          email: this.inputValue.email,
          phoneNumber: this.inputValue.phone,
          displayName: this.inputValue.first_name,
          user_type: this.inputValue.type,
          social_id: this.inputValue.social_id
        })
      } catch (err) {

      }

      // reset values for next page
      this.allFieldsValid = false
      this.userErrorMessage = null

      this.loading = false
      this.$router.push('/quiz')
    },

    async nextStep () {
      this.checkInputValues('all')
      // prevents next if not all fields are entered.
      if (!this.allFieldsValid) {
        return false
      }
      await this.createUserAccount()
      window.scrollTo(0, 0)
    },

    showModalSignup () {
      this.setModalSignup(true)
    },

    closeModalSignup () {
      this.setModalSignup(false)
    },

    onSuccess (data) {
      this.signedIn = true
    },

    onSuccessLogout () {
      this.signedIn = false
    },

    onFailure (error) {
      console.error(error)
    }
  },
  async mounted () {
    const route = useRoute()
    //
    if (route?.query?.social) {
      var decodedStr = Base64.decode(route?.query?.social)
      const socialData = JSON.parse(decodedStr)
      if (socialData.email) {
        this.loading = true
        await this.nextStep()
      }
    }
  }
}
</script>
<script setup>

</script>
<style lang="scss" scoped>
.quiz__container--full {
  display: grid;
  grid-column: span 12;

  p {
    margin-bottom: calc(var(--leading) * .667);
    text-align: center;
    font-size: 0.8rem;
  }

  a.underline {
    text-decoration: underline;
  }
}
.show-password__reveal--text {
  color: $tt-off-white;
}
.social-btn{
  display:flex;
  align-items: center;
  justify-content: center;
}

#appleid-signin svg text {
  display:none !important;
}

.social-btn > div {
    margin: 5px;
    width:auto;
}

.social-auth img {
  width:45px;
}

div#appleid-signin {
    height: 48px;
    width: 48px;
    border-radius: 50px;
    overflow: hidden;
    cursor: pointer;
}

.social-work {
  display:block;
}
.social-work .quiz__navigation {
      position: relative !important;
}

.social-work .social-btn {
  margin-top:2rem;
}
.g-social-btn{
  width:45px;
  height:45px;
  padding:.5rem;
}
.g-social-btn img{ width: 20px;}
.g-btn-wrapper {
  margin-top:2rem;
}
button.login-button {
  background-color: white;
  height: 32px;
  width: 200px;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  font-weight: 500;
  margin: 5px;
}
button.login-button svg{
  height: 15px;
  margin-top: -3px;
  margin-right: 3px;
}

.social-btn{
  display:flex;
  align-items: center;
  justify-content: center;
}

#appleid-signin svg text {
  display:none !important;
}

.social-btn > div {
    margin: 5px;
    width:auto;
}
.social-auth img {
  width:45px;
}
div#appleid-signin {
    height: 48px;
    width: 48px;
    border-radius: 50px;
    overflow: hidden;
    cursor: pointer;
}
</style>
